/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Macbook(props) {
  const { nodes, materials } = useGLTF("/macbook_pro_badger.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        name="screen"
        geometry={nodes.screen.geometry}
        material={materials["inner-screen"]}
        position={[0.000168, -0.000938, -0.118742]}
      />
      <group name="base" position={[0, 0.000271, 0]}>
        <mesh
          name="Plane002"
          geometry={nodes.Plane002.geometry}
          material={materials["keys black"]}
        />
        <mesh
          name="Plane002_1"
          geometry={nodes.Plane002_1.geometry}
          material={materials["keys-edge"]}
        />
        <mesh
          name="Plane002_2"
          geometry={nodes.Plane002_2.geometry}
          material={materials["brushed metal_body"]}
        />
        <mesh
          name="Plane002_3"
          geometry={nodes.Plane002_3.geometry}
          material={materials["brushed metal_holes"]}
        />
        <mesh
          name="Plane002_4"
          geometry={nodes.Plane002_4.geometry}
          material={nodes.Plane002_4.material}
        />
        <mesh
          name="Plane002_5"
          geometry={nodes.Plane002_5.geometry}
          material={materials.ports}
        />
        <mesh
          name="Plane002_6"
          geometry={nodes.Plane002_6.geometry}
          material={materials["black rubber"]}
        />
        <mesh
          name="Plane002_7"
          geometry={nodes.Plane002_7.geometry}
          material={materials["trackpad brushed metal"]}
        />
      </group>
      <group
        name="lid"
        position={[0.000208, -0.000904, -0.118744]}
        rotation={[-1.912462, 0, 0]}
        scale={[1, 0.285714, 1]}
      >
        <mesh
          name="Cube015"
          geometry={nodes.Cube015.geometry}
          material={materials["brushed metal_body"]}
        />
        <mesh
          name="Cube015_1"
          geometry={nodes.Cube015_1.geometry}
          material={materials["black rubber"]}
        />
        <mesh
          name="Cube015_2"
          geometry={nodes.Cube015_2.geometry}
          material={materials["outer-lid"]}
        />
        <mesh
          name="Cube015_3"
          geometry={nodes.Cube015_3.geometry}
          material={materials["bottom-screen-edge"]}
        />
        <mesh
          name="Cube015_4"
          geometry={nodes.Cube015_4.geometry}
          material={materials["bottom-edge-rubber"]}
        />
        <mesh
          name="Cube015_5"
          geometry={nodes.Cube015_5.geometry}
          material={materials["screen black"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/macbook_pro_badger.glb");


