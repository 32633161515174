import { Html, PresentationControls, Float, Center, Environment, useGLTF, OrbitControls, ContactShadows } from '@react-three/drei'
import { Macbook } from './Macbook'
import { useControls } from 'leva'


export default function Experience()
{
  /* const computer=useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf') */
  const computer=useGLTF('./macbook_pro_badger.glb')

  /* const iframeChoices= {
    Airport: {
      choiceName: 'https://dev.badgerz.ink/airport_v4.1',
      bg: "#000000",
      screenLighting: "#ffffff"
    },
    Conference: {
      choiceName: 'https://dev.badgerz.ink/airport_v4.1',
      bg: "#ff0000",
      screenLighting: "#ff0000"
    },
    Pinata: {
      choiceName: 'https://dev.badgerz.ink/airport_v4.1',
      bg: "#f00f00",
      screenLighting: "#00ff00"
    } */
    const iframeChoices={
      Pinata: 'https://test5.badgerz.ink',
      Airport: 'https://dev.badgerz.ink/airport_v4.1',
      Conference: 'https://test.badgerz.ink'
    
  }
  const {choice, background, screenLight} = useControls( {
    choice: { options: iframeChoices },
    background: { value: '#ebc4d6', label:'Background'},
    screenLight: { value: '#f9abc8', label: 'Screen Light'}
  })
    return <>

        <color args={ [background] } attach="background" />

        <Environment preset="city" />
        <OrbitControls 
            makeDefault
            minPolarAngle={1}
            maxPolarAngle={1.6}
            minAzimuthAngle={-1}
            maxAzimuthAngle={1}
            minDistance={0.8}
            maxDistance={6}

        />
        
        <Center>
            {/* <primitive object={ computer.scene} position-y={-1.2} scale={10} /> */}
            {/* <PresentationControls 
                global
                rotation={ [0.13,0,0] }
                polar={ [ -0.4, 0.2 ] }
                azimuth={ [ -1, 0.75 ] }
                config={ {mass:1, tension:400}}
                snap={ {mass:4, tension:400} }
            >
                
            </PresentationControls> */}
             {/* <Float rotationIntensity={ 0.4} > */}
                   
                    <primitive object={ computer.scene} position-y={-1.2} scale={10}>
                      <rectAreaLight 
                        width={2.5}
                        height={1.65}
                        intensity={65}
                        color={ screenLight}
                        /* color={'#107e9f'} */
                        rotation={ [ 0.1,Math.PI, 0]}
                        position={ [ 0,0.115,-0.167 ] }
                      />
                      <Html 
                        transform
                        wrapperClass="htmlScreen"
                        /* distanceFactor={ 0.135 } */
                        distanceFactor={ 0.072}
                        position={ [ 0, 0.115, -0.167 ] }
                        rotation-x={ -0.346 }
                      >
                        <iframe src={ choice }></iframe>
                      </Html>
                    </primitive>
              {/* </Float> */}
        </Center>
        <ContactShadows 
            position-y={-1.18}
            opacity={ 0.8 }
            scale={ 5 }
            blur={ 2.4 }
        />
        
    </>
}